export const environment = {
  production: true,
  apiUrl: "https://betaapi.adit.com",
  pfapiUrl:"https://betaapi.adit.com",
  domain: "https://betaform.adit.com",
  static: "https://betastatic.adit.com",
  ipinfoUrl: "https://ipinfo.io?token=faad8493f922ce",
  websocket_url: '',
  staticBaseUrl: "https://betastatic.adit.com",
  imageBaseUploadUrl: "https://betastatic.adit.com/uploads/",
  staticUrl: "https://betastatic.adit.com",
  socketUrlNchan: "wss://betagsocket.adit.com/",
  firebase: {
    apiKey: "AIzaSyDG8Kps4kZVVmrZ3zAQ5y73S_DyoCksOEU",
    authDomain: "adit-forms.firebaseapp.com",
    projectId: "adit-forms",
    storageBucket: "adit-forms.appspot.com",
    messagingSenderId: "338646571977",
    appId: "1:338646571977:web:ac499613cc8645a487e618",
    measurementId: "G-R0TTVFJ6QB"
  },
  stripe: {
    apiKey: 'pk_test_51Ixs95BTDnqJlDMTBv1XdQXE7Ny65HKkGAPB3j2IE1wwqYSgLW6RF1HzBDZoy8pSYsX13rxwW321P5d1LQqvk8ym002ZfUFPEG'
  },
  upgrade_link: {
    android: 'https://play.google.com/store/apps/details?id=com.adit.forms',
    ios: 'https://apps.apple.com/in/app/adit-patient-forms/id6453475342'
  },
  cdnUrl: {
    'root': 'https://betacdn.adit.com/',
    'common': 'https://betacdn.adit.com/common/'
  }
};