// External Imports
import { Injectable, Injector } from '@angular/core';
import { Http } from '@capacitor-community/http';
import { HttpOptions } from '@capacitor/core';
// Internal Imports
import { environment } from '../../../environments/environment';
import { LoaderService } from '../components/loader/loader.service';
import { CommonMethodService } from './common-method.service';
import { UserService } from './user.service';
import { MessageService } from './message.service';
import { Network } from '@capacitor/network';


@Injectable({
    providedIn: 'root'
})
export class CommonApiService {
    // Default Parameters
    httpDefaults: HTTPParam = {
        apiName: null,
        parameterObject: {},
        methodType: 'post',
        showLoading: false,
        baseUrlKey: environment.pfapiUrl,
    }

    // Loading Bar Watcher
    // private isDataLoadingChange: EventEmitter<boolean> = new EventEmitter();
    // Tracking API Calls
    apiInProcess: any = []
    public platform = this.commonMethodService.getPlatform();

    private hideLoader(parameters: HTTPParam) {
        if (parameters.showLoading) {
            let isExists = this.apiInProcess.findIndex(apiName => apiName === parameters.apiName);
            if (isExists !== -1) {
                this.apiInProcess.splice(isExists, 1);
            }
            if (this.apiInProcess.length === 0) {
                this.loaderService.hide();
            }
        }
    }

    // Constructor
    constructor(private injector: Injector, private loaderService: LoaderService, private commonMethodService: CommonMethodService) { }

    /**
     * POST REQUEST TO THE HTTP USING Observable
     * @param {string} apiName - API Name 
     * @param {*} [parameterObject] - Parameter to pass in request (By Default = {})
     * @returns {Observable<any>} 
     * @memberof CommonAPIService
     */
    private post(apiName: string, parameterObject?: any, baseUrl?: any, header?: any): Promise<any> {
        if (this.platform === 'ios') {
            return new Promise((resolve, reject) => {
                if (!header) {
                    header = {
                        'content-type': 'application/json'
                    };
                }
                if (!(header && header['content-type'])) {
                    header['content-type'] = 'application/json'
                }
                let token = localStorage.getItem('secretToken');
                const options: HttpOptions = {
                    url: `${(baseUrl || environment.pfapiUrl) + apiName}`,
                    headers: {
                        'Authorization': token || undefined,
                        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check = 0, pre - check=0',
                        'Pragma': 'no-cache',
                        'Expires': '0',
                        'X-SOURCE': 'formapp',
                        ...header
                    },
                    data: parameterObject,

                };
                Http.post(options).then((result) => {
                    resolve(this.interceptResponse(result.data))
                }).catch((error) => {
                    console.log('postFileError', error, error.message);
                    reject({ type: 'error', title: '', message: 'Something went wrong. Please try again!'});
                });
            })
        } else {
            return new Promise((resolve, reject) => {
                fetch(`${(baseUrl || environment.pfapiUrl) + apiName}`, this.interceptRequest('POST', parameterObject, header))
                    .then((result) => result.json())
                    .then(response => {
                        resolve(this.interceptResponse(response))
                    })
                    .catch((error) => {
                        reject({ type: 'error', title: '', message: 'Something went wrong. Please try again!'});
                    });
            })
            // return this.http.post(`${(baseUrl || environment.apiUrl) + apiName}`, parameterObject, { headers: header }).pipe(map((res: any) => {
            //     return res || {};
            // })).toPromise();
        }
    }

    /**
     * PUT REQUEST TO THE HTTP USING Observable
     * @param {string} apiName - API Name 
     * @param {*} [parameterObject] - Parameter to pass in request (By Default = {})
     * @returns {Observable<any>} 
     * @memberof CommonAPIService
     */
    // private put(apiName: string, parameterObject?: any, baseUrl?: any, header?: any): Observable<any> {
    //     return this.http.put(`${(baseUrl || environment.apiUrl) + apiName}`, parameterObject, { headers: header }).pipe(map((res: any) => {
    //         return res || {};
    //     }));
    // }

    /**
     * PATCH REQUEST TO THE HTTP USING Observable
     * @param {string} apiName - API Name 
     * @param {*} [parameterObject] - Parameter to pass in request (By Default = {})
     * @returns {Observable<any>} 
     * @memberof CommonAPIService
     */
    // private patch(apiName: string, parameterObject?: any, baseUrl?: any, header?: any): Observable<any> {
    //     return this.http.patch(`${(baseUrl || environment.apiUrl) + apiName}`, parameterObject, { headers: header }).pipe(map((res: any) => {
    //         return res || {};
    //     }));
    // }


    /**
     * DELETE REQUEST TO THE HTTP USING Observable
     * @param {string} apiName - API Name 
     * @param {*} [parameterObject] - Parameter to pass in request (By Default = {})
     * @returns {Observable<any>} 
     * @memberof CommonAPIService
     */
    // private delete(apiName: string, parameterObject?: any, baseUrl?: any, header?: any): Observable<any> {
    //     return this.http.delete(`${(baseUrl || environment.apiUrl) + apiName}`, { params: parameterObject, headers: header }).pipe(map((res: any) => {
    //         return res || {};
    //     }));
    // }

    /**
     * GET REQUEST TO THE HTTP USING Observable
     * @param {string} apiName - API Name 
     * @param {*} [parameterObject] - Parameter to pass in request (By Default = {})
     * @returns {Observable<any>} 
     * @memberof CommonAPIService
     */
    private get(apiName: string, parameterObject?: any, baseUrl?: any, header?: any): Promise<any> {
        if (this.platform === 'ios') {
            return new Promise((resolve, reject) => {
                let token = localStorage.getItem('secretToken');
                for (let key in parameterObject) {
                    if (typeof parameterObject[key] !== 'string') {
                        parameterObject[key] = JSON.stringify(parameterObject[key]);
                    }
                }
                const options = {
                    url: `${(baseUrl || environment.pfapiUrl) + apiName}`,
                    headers: {
                        'Authorization': token || undefined,
                        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check = 0, pre - check=0',
                        'Pragma': 'no-cache',
                        'Expires': '0',
                        'X-SOURCE': 'formapp',
                        ...header
                    },
                    params: parameterObject
                };
                Http.get(options).then((result) => {
                    resolve(this.interceptResponse(result.data))
                }).catch((error) => {
                    reject({ type: 'error', title: '', message: 'Something went wrong. Please try again!'});
                });
            })
        } else {
            return new Promise((resolve, reject) => {
                fetch(`${(baseUrl || environment.pfapiUrl) + apiName}`, this.interceptRequest('GET', parameterObject, header))
                    .then((result) => result.json())
                    .then(response => {
                        resolve(this.interceptResponse(response))
                    })
                    .catch((error) => {
                        console.log('postFileError', error, error.message);
                        reject({ type: 'error', title: '', message: 'Something went wrong. Please try again!'});
                    });
            })
        }
    }

    private async interceptResponse(response: any) {
        if (!response.status && response.code === 13) {
            const userService = this.injector.get(UserService);
            const messageService = this.injector.get(MessageService);
            try {
                await userService.logout();
                messageService.showMessage({ type: 'error', title: '', message: response?.message || 'Session Expired.' })
            } catch (err) {
                messageService.showMessage({ type: 'error', title: '', message: '' })
            }
            return response;
        } else {
            return response;
        }
    }

    private interceptRequest(methodType, requestObject: any, headers: any) {
        let token = localStorage.getItem('secretToken');
        return {
            method: methodType,
            headers: {
                Authorization: token,
                'Cache-Control': 'no-cache, no-store, must-revalidate, post-check = 0, pre - check=0',
                'Pragma': 'no-cache',
                'Expires': '0',
                'X-SOURCE': 'formapp',
                ...headers
            },
            body: methodType !== 'GET' ? JSON.stringify(requestObject) : undefined, // body data type must match "Content-Type" header
        }
    }

    /**
     * 
     * GET HTTP RESPONSE USING Observable
     * @param {string} apiName API NAME
     * @param {any} [parameterObject={}] 
     * @param {string} [methodType="post"] 
     * @param {boolean} [showLoading=true] 
     * @returns {*} 
     * @memberof CommonAPIService
     */
    // public getObservableResponse(httpParam: HTTPParam): Observable<any> {
    //     const parameters = Object.assign({}, this.httpDefaults, httpParam);
    //     switch (parameters.methodType) {
    //         case 'post': return this.post(parameters.apiName, parameters.parameterObject);
    //         case 'get': return this.get(parameters.apiName, parameters.parameterObject);
    //     }
    // }

    /**
    * 
    * GET HTTP RESPONSE USING PROMISE
    * @param {string} apiName API NAME
    * @param {any} [parameterObject={}] 
    * @param {string} [methodType="post"] 
    * @param {boolean} [showLoading=true] 
    * @returns {*} 
    * @memberof CommonAPIService
    */
    public async getPromiseResponse(httpParam: HTTPParam, header?: any, hasLoading: boolean = true): Promise<any> {
        const parameters = Object.assign({}, this.httpDefaults, httpParam);
        if (parameters.showLoading) {
            this.apiInProcess.push(parameters.apiName);
            if (!hasLoading) {
                this.loaderService.hide();
            }
            else {
                this.loaderService.show();
            }
        }
        let isInternetConnected = await this.checkInternetConnectivity()
        if (isInternetConnected) {
            switch (parameters.methodType) {
                case 'post': return new Promise((resolve, reject) => {
                    this.post(parameters.apiName, parameters.parameterObject, parameters.baseUrlKey, (header || {'content-type': 'application/json'})).then(response => {
                        resolve(response);
                        this.hideLoader(parameters);
                    }, (error: any) => {
                        this.hideLoader(parameters);
                        this.loaderService.hide();
                        if (navigator.onLine) {
                            reject({ status: false, code: 0, error: 'error', message: 'Something went wrong. Please try again!' });
                        } else {
                            reject({ status: false, code: 0, error: 'INTERNET_DISCONNECTIVITY', message: 'Please check your internet settings and try again' });
                        }
                    }).catch(error => {
                        this.hideLoader(parameters);
                        this.loaderService.hide();
                        if (navigator.onLine) {
                            reject({ status: false, code: 0, error: 'error', message: 'Something went wrong. Please try again!' });
                        } else {
                            reject({ status: false, code: 0, error: 'INTERNET_DISCONNECTIVITY', message: 'Please check your internet settings and try again' });
                        }
                    });
                });

                case 'get': return new Promise((resolve, reject) => {
                    this.get(parameters.apiName, parameters.parameterObject, parameters.baseUrlKey, header).then(response => {
                        resolve(response);
                        this.hideLoader(parameters);
                    }, error => {
                        this.hideLoader(parameters);
                        this.loaderService.hide();
                        if (navigator.onLine) {
                            reject({ status: false, code: 0, error: 'error', message: 'Something went wrong. Please try again!' });
                        } else {
                            reject({ status: false, code: 0, error: 'INTERNET_DISCONNECTIVITY', message: 'Please check your internet settings and try again' });
                        }
                    }).catch(error => {
                        this.hideLoader(parameters);
                        this.loaderService.hide();
                        if (navigator.onLine) {
                            reject({ status: false, code: 0, error: 'error', message: 'Something went wrong. Please try again!' });
                        } else {
                            reject({ status: false, code: 0, error: 'INTERNET_DISCONNECTIVITY', message: 'Please check your internet settings and try again' });
                        }
                    });
                });

                // case 'put': return new Promise((resolve, reject) => {
                //     this.put(parameters.apiName, parameters.parameterObject, parameters.baseUrlKey, header).toPromise().then(response => {
                //         resolve(response);
                //         this.hideLoader(parameters);
                //     }, (error: any) => {
                //         this.hideLoader(parameters);
                //         this.loaderService.hide();
                //         reject(error.error);
                //     }).catch(error => {
                //         this.hideLoader(parameters);
                //         this.loaderService.hide();
                //         reject(error.error);
                //     });
                // });

                // case 'patch': return new Promise((resolve, reject) => {
                //     this.patch(parameters.apiName, parameters.parameterObject, parameters.baseUrlKey, header).toPromise().then(response => {
                //         resolve(response);
                //         this.hideLoader(parameters);
                //     }, (error: any) => {
                //         this.hideLoader(parameters);
                //         this.loaderService.hide();
                //         reject(error.error);
                //     }).catch(error => {
                //         this.hideLoader(parameters);
                //         this.loaderService.hide();
                //         reject(error.error);
                //     });
                // });

                // case 'delete': return new Promise((resolve, reject) => {
                //     this.delete(parameters.apiName, parameters.parameterObject, parameters.baseUrlKey, header).toPromise().then(response => {
                //         resolve(response);
                //         this.hideLoader(parameters);
                //     }, (error: any) => {
                //         this.hideLoader(parameters);
                //         this.loaderService.hide();
                //         reject(error.error);
                //     }).catch(error => {
                //         this.hideLoader(parameters);
                //         this.loaderService.hide();
                //         reject(error.error);
                //     });
                // });
            }
        } else {
            return new Promise((resolve, reject) => {
                reject({ status: false, code: 0, error: 'INTERNET_DISCONNECTIVITY', message: 'Please check your internet settings and try again' });
            });
        }
    }

    public async checkInternetConnectivity() {
        if (this.platform === 'ios' || this.platform === 'android') {
            return (await Network.getStatus()).connected;
        } else {
            return navigator.onLine;
        }
    }
}


export interface HTTPParam {
    apiName: string,
    parameterObject?: any;
    methodType?: any;
    showLoading?: boolean,
    baseUrlKey?: any
};